import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { Paragraph } from '../styled/typography'
import { Button } from '../styled/button'
import { Plan } from '../styled/plan'

const Container = styled.div``

const ContinueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(var(--spacing) * 12);
`

const StyledButton = styled(Button)`
  font-size: 16px;
  padding: 10px;
`

const PlanesContainer = styled.div``
const StyledPlan = styled(Plan)`
  :hover {
    background: ${p =>
      p.selected ? 'var(--secondary)' : 'var(--secondary-light)'};
    border-color: ${p =>
      p.selected ? 'var(--secondary)' : 'var(--secondary-light)'};
  }
`

const BecomeTrusteeOne = ({
  onContinue,
  selectedPlan,
  plans,
  onSelectPlan,
}) => {
  return (
    <Container>
      <ContinueContainer>
        <Paragraph>Choose a package</Paragraph>{' '}
        <StyledButton onClick={onContinue}>Continue</StyledButton>
      </ContinueContainer>
      <PlanesContainer>
        {plans.map(item => (
          <StyledPlan
            key={item.id}
            selected={selectedPlan && selectedPlan.id === item.id}
            onClick={() => onSelectPlan(item)}
          >
            <h6>
              <b>{item.price}$</b> / month
            </h6>
            <small>{item.shortDescription}</small>
          </StyledPlan>
        ))}
      </PlanesContainer>
    </Container>
  )
}

export default BecomeTrusteeOne
