import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { Button } from '../styled/button'

const Container = styled.div``
const StyledButton = styled(Button)`
  margin-top: calc(var(--spacing) * 6);
  width: 100%;
`
const Text = styled.h6`
  text-align: center;
  font-weight: normal;
`
const FinalStep = ({ onFinal }) => {
  return (
    <Container>
      <Text>You're now a trustee</Text>
      <StyledButton onClick={onFinal}>Back to profile</StyledButton>
    </Container>
  )
}

export default FinalStep
